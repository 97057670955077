import gql from 'graphql-tag';

export const CO2_CALCULATOR_FORM_VALUES_FRAGMENT = gql`
  fragment co2CalculatorFormValues on CO2CalculatorFormValues {
    heated_living_area
    kind_of_house
    energy_standard
    renovations
    solar_heating_system_type
    number_of_people
    heating_system
    is_solar_heating_system
    age_of_heating_system
    temperature
    hot_water_producer
    amount_of_hot_water
    is_devices_younger_than_10_years
    power_consumers
    is_solar_power_system
    solar_power_system_size
  }
`;
