import { Header } from 'components/common/heap';
import { get, isEqual } from 'lodash';
import React, { useMemo } from 'react';
import { Col, Row } from 'react-grid-system';
import Loading from 'components/common/Loading';
import useGetCO2Calculations from 'hooks/calculator/useCO2Calculations';
import EnergyReductionItems from './EnergyReductionItems/EnergyReductionItems';
import { EnergyScaleClasses } from './EnergyClassesAndIndicators/EnergyClassesAndIndicators';
import EnergyGhgEquivalents from './EnergyGhgEquivalents';
import { useTranslation } from 'react-i18next';
import s from './CO2StatusProjectPlanSection.module.scss';
import Alert from 'components/common/Alert';
import { formatGraphqlErrors } from 'utils/helpers';

const CO2StatusProjectPlanSection = ({ projectId }) => {
  const { t } = useTranslation();
  const { co2Calculations, loading, error } = useGetCO2Calculations(projectId);

  const before = get(co2Calculations, 'co2_emissions_before.result', null);
  const after = get(co2Calculations, 'co2_emissions_after.result', null);

  const equivalentValues = useMemo(() => {
    const values = { energyGhgEquivalentPlane: 0, energyGhgEquivalentCar: 0, energyGhgEquivalentTrees: 0 };
    if (before && after) {
      values.energyGhgEquivalentPlane = before.energy_ghg_equivalent_plane - after.energy_ghg_equivalent_plane;
      values.energyGhgEquivalentCar = before.energy_ghg_equivalent_car - after.energy_ghg_equivalent_car;
      values.energyGhgEquivalentTrees = before.energy_ghg_equivalent_trees - after.energy_ghg_equivalent_trees;
    }
    return values;
  }, [before, after]);

  if (loading) return <Loading size={30} />;
  if (error) return <Alert color="danger">{formatGraphqlErrors(error).message}</Alert>;
  if (!before || !after || isEqual(before, after)) return null;

  return (
    <Row>
      <Col xs={12} className="mb-4">
        <div className={s.headerWrapper}>
          <Header className="mb-0">
            <h3>{t('co2CalculatorResult.houseEnergyBalance.beforeAndAfterHeader')}</h3>
          </Header>
        </div>
      </Col>
      <Col xl={7}>
        <EnergyScaleClasses {...{ co2Calculations, t, costOverviewTab: true }} />
      </Col>
      <Col xl={5}>
        <EnergyReductionItems {...{ t, co2Calculations, type: 'after' }} />
      </Col>
      <Col xs={12}>
        <div className="my-4">
          <EnergyGhgEquivalents {...{ equivalentValues, costOverviewTab: true }} />
        </div>
      </Col>
    </Row>
  );
};

export default CO2StatusProjectPlanSection;
