import gql from 'graphql-tag';
import get from 'lodash/get';
import { useQuery } from '@apollo/client';

export const PROJECT_PLAN_FRAGMENT = gql`
  fragment projectPlan on ProjectPlan {
    price
    planningCategories {
      name
      label
      description
      calculated_price
      user_price
      equipment_type
    }
  }
`;

export const PROJECT_OVERVIEW_QUERY = gql`
  query projectOverview($projectId: ID!) {
    projectPlan(projectId: $projectId) {
      ...projectPlan
    }
    project(id: $projectId) {
      _id
      price
      type
      form_values {
        ... on RenovationHouseFormValues {
          battery_storage_size
        }
      }
    }
    co2Calculations(projectId: $projectId) {
      _id
      energy_renovations {
        new_windows {
          co2_saving
          saving_energy_costs
        }
        insulation_top_ceiling {
          co2_saving
          saving_energy_costs
        }
        insulation_basement_ceiling {
          co2_saving
          saving_energy_costs
        }
        insulation_facade {
          co2_saving
          saving_energy_costs
        }
        controlled_living_space_ventilation {
          co2_saving
          saving_energy_costs
        }
        solar_power_system {
          co2_saving
          saving_energy_costs
        }
        heating_system {
          co2_saving
          saving_energy_costs
        }
      }
    }
  }
  ${PROJECT_PLAN_FRAGMENT}
`;

const useProjectOverview = (projectId) => {
  const { data, loading, error } = useQuery(PROJECT_OVERVIEW_QUERY, { variables: { projectId } });
  const projectPlan = get(data, 'projectPlan', null);
  const project = get(data, 'project', null);
  const energyRenovations = get(data, 'co2Calculations.energy_renovations', null);

  return { projectPlan, project, energyRenovations, loading, error };
};

export default useProjectOverview;
