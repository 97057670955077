import React, { useMemo } from 'react';
import { camelCase, get, max, min, pick } from 'lodash';
import { formatGraphqlErrors, formatValue } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import useGetCO2Calculations from 'hooks/calculator/useCO2Calculations';
import Loading from 'components/common/Loading';
import Alert from 'components/common/Alert';

const customEnergyRequirementWhiteList = [
  'heating_oil',
  'coal',
  'natural_gas',
  'firewood',
  'district_heating',
  'wood_chips',
  'wood_pellets',
  'electricity_used',
];

const calculateTotal = (array, key) => array.reduce((s, c) => s + c[key], 0);

export const getUserOrCalculatedValue = (obj) => {
  return Number.isFinite(obj.user_input) ? obj.user_input : obj.calculated_value;
};

export const getUserOrCalculatedValueLabel = (obj, t) => {
  const isUserValue = Number.isFinite(obj.user_input);
  return `${formatValue(isUserValue ? obj.user_input : obj.calculated_value)} ${
    isUserValue ? `(${t('co2CalculatorResult.userValue')}) ` : ''
  } kWh/a`;
};

const getFuelMatrix = (customEnergyRequirements) => {
  return Object.entries(pick(customEnergyRequirements, customEnergyRequirementWhiteList))
    .filter(([, v]) => v.user_value)
    .map(([k, { energy, costs, user_value, ghg }]) => ({
      fuelType: camelCase(k),
      energy,
      costs,
      userInput: user_value,
      ghg,
    }));
};

const calculatePrimaryEnergyForHeatingBefore = ({ customEnergyRequirements, before }) => {
  const heatingTotal = customEnergyRequirementWhiteList
    .filter((c) => c !== 'electricity_used')
    .reduce((acc, c) => acc + customEnergyRequirements[c].energy, 0);
  const value = heatingTotal || getUserOrCalculatedValue(before.primary_energy_for_heating);
  return { type: heatingTotal ? 'custom' : 'system', value };
};

const calculateTotalElectricPowerConsumption = ({ calculations }) => {
  const { form_values, result } = calculations;
  const { heating_system, hot_water_producer } = form_values;
  const { total_power_consumption_per_year, primary_energy_for_heating, primary_energy_for_hot_water } = result;

  const totalConsumption = getUserOrCalculatedValue(total_power_consumption_per_year);
  const primaryEnergyForHeating = getUserOrCalculatedValue(primary_energy_for_heating);
  const primaryEnergyForHotWater = getUserOrCalculatedValue(primary_energy_for_hot_water);

  const heating = ['heat_pump', 'electric'].includes(heating_system) ? primaryEnergyForHeating : 0;
  const hotWater = ['electric_boiler', 'heat_pump'].includes(hot_water_producer) ? primaryEnergyForHotWater : 0;
  const hotWaterWithHeating =
    hot_water_producer === 'heating_system' && ['heat_pump', 'electric'].includes(heating_system)
      ? primaryEnergyForHotWater
      : 0;

  return totalConsumption + heating + hotWater + hotWaterWithHeating;
};

const getColumns = (t) => {
  return [
    {
      key: 'label',
      header: '',
      render: ({ label }) => <div style={{ textAlign: 'left' }}>{t(label)}</div>,
      width: '40%',
    },
    {
      key: 'before_renovation',
      header: 'co2CalculatorResult.valuesBeforeAndAfter.beforeRenovation',
      render: ({ beforeRenovation }) =>
        typeof beforeRenovation === 'number' ? `${formatValue(beforeRenovation)} kWh/a` : beforeRenovation,
      width: '30%',
    },
    {
      key: 'after_renovation',
      header: 'co2CalculatorResult.valuesBeforeAndAfter.afterRenovation',
      render: ({ afterRenovation }) =>
        typeof afterRenovation === 'number' ? `${formatValue(afterRenovation)} kWh/a` : afterRenovation,
      width: '30%',
    },
  ];
};

const getData = ({ co2Calculations, t, project }) => {
  const before = get(co2Calculations, 'co2_emissions_before.result');
  const after = get(co2Calculations, 'co2_emissions_after.result');
  const { form_values: projectFV } = project;
  const customEnergyRequirements = get(co2Calculations, 'custom_energy_requirements', {});
  const { electricity_used } = customEnergyRequirements;
  const isCustomEnergyRequirements = Object.values(customEnergyRequirements || {}).some(({ user_value }) => user_value);

  const fuelMatrix = getFuelMatrix(customEnergyRequirements);

  const totalKWh = calculateTotal(fuelMatrix, 'energy');
  const totalElectricPowerConsumptionBefore =
    electricity_used.user_value ||
    calculateTotalElectricPowerConsumption({ calculations: co2Calculations.co2_emissions_before });
  const totalElectricPowerConsumptionAfter = calculateTotalElectricPowerConsumption({
    calculations: co2Calculations.co2_emissions_after,
  });

  const totalEnergyDemandBefore = isCustomEnergyRequirements
    ? totalKWh
    : getUserOrCalculatedValue(before.total_energy_balance_per_year);
  const totalEnergyDemandAfter = getUserOrCalculatedValue(after.total_energy_balance_per_year);

  const selfConsumptionOfProducedElectricityBefore = min([
    before.own_consumption,
    projectFV.battery_storage_size ? totalElectricPowerConsumptionBefore * 0.7 : totalElectricPowerConsumptionBefore,
  ]);
  const primaryEnergyForHeatingBefore = calculatePrimaryEnergyForHeatingBefore({ customEnergyRequirements, before });
  return [
    {
      label: 'co2CalculatorResult.valuesBeforeAndAfter.totalEnergyDemand',
      beforeRenovation: totalEnergyDemandBefore,
      afterRenovation: totalEnergyDemandAfter,
    },
    {
      label: 'co2CalculatorResult.valuesBeforeAndAfter.totalElectricPowerConsumption',
      beforeRenovation: totalElectricPowerConsumptionBefore,
      afterRenovation: totalElectricPowerConsumptionAfter,
    },
    {
      label: 'co2CalculatorResult.valuesBeforeAndAfter.totalPowerElectricPowerProduction',
      beforeRenovation: before.total_power_production_per_year,
      afterRenovation: after.total_power_production_per_year,
    },
    {
      label: 'co2CalculatorResult.valuesBeforeAndAfter.selfConsumptionOfProducedElectricity',
      beforeRenovation: selfConsumptionOfProducedElectricityBefore,
      afterRenovation: after.own_consumption,
    },
    {
      label: 'co2CalculatorResult.valuesBeforeAndAfter.electricPowerConsumptionFromEnergySupplyCompany',
      beforeRenovation: totalElectricPowerConsumptionBefore - selfConsumptionOfProducedElectricityBefore,
      afterRenovation: totalElectricPowerConsumptionAfter - after.own_consumption,
    },
    {
      label: 'co2CalculatorResult.valuesBeforeAndAfter.electricalFeedIntoGrid',
      beforeRenovation: max([before.total_power_production_per_year - selfConsumptionOfProducedElectricityBefore, 0]),
      afterRenovation: max([after.electrical_feed_into_grid, 0]),
    },
    {
      label: 'co2CalculatorResult.valuesBeforeAndAfter.primaryEnergyForHotWater',
      beforeRenovation:
        primaryEnergyForHeatingBefore.type === 'custom'
          ? t('co2CalculatorResult.valuesBeforeAndAfter.hotWaterIsPartOfHeating')
          : getUserOrCalculatedValue(before.primary_energy_for_hot_water),
      afterRenovation: getUserOrCalculatedValue(after.primary_energy_for_hot_water),
    },
    {
      label: 'co2CalculatorResult.valuesBeforeAndAfter.primaryEnergyForHeating',
      beforeRenovation: primaryEnergyForHeatingBefore.value,
      afterRenovation: getUserOrCalculatedValue(after.primary_energy_for_heating),
    },
  ];
};

const BeforeAndAfterOverview = ({ project, co2Calculations }) => {
  const { t } = useTranslation();
  const data = useMemo(() => getData({ co2Calculations, project, t }), [co2Calculations, project, t]);

  const columns = useMemo(() => getColumns(t), [t]);

  return (
    <div style={{ paddingTop: '1rem', pageBreakInside: 'avoid' }}>
      <h2 className="text-primary">{t('co2CalculatorResult.valuesBeforeAndAfter.tableTitle')}</h2>
      <table className="table">
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.header}>{t(column.header)}</th>
            ))}
          </tr>
        </thead>
        <colgroup>
          {columns.map(({ key, width }) => (
            <col key={key} style={width ? { width } : {}} />
          ))}
        </colgroup>
        <tbody>
          {data.map((item) => (
            <tr key={item.label}>
              {columns.map((cell) => (
                <td key={cell.key} style={{ textAlign: 'right' }}>
                  {cell?.render(item)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <p>{t('co2CalculatorResult.heatingResult.primaryEnergyForHeatingDescription')}</p>
    </div>
  );
};

const BeforeAndAfterOverviewContainer = ({ project }) => {
  const { co2Calculations, loading, error } = useGetCO2Calculations(project._id);

  if (loading) return <Loading size={40} />;

  if (error) return <Alert color="danger">{formatGraphqlErrors(error).message}</Alert>;

  if (!co2Calculations?.co2_emissions_after) return null;

  return <BeforeAndAfterOverview {...{ project, co2Calculations }} />;
};

export default BeforeAndAfterOverviewContainer;
