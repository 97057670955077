import React, { useCallback, useMemo, useState } from 'react';
import { Row, Col } from 'react-grid-system';
import { formatCurrency, formatValue } from 'utils/helpers';
import { Header } from 'components/common/heap';
import s from './ProjectPlanTables.module.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DropUpArrow } from 'assets/icons/drop-up-arrow.svg';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
} from '@tanstack/react-table';
import OverviewPieCharts from './OverviewPieCharts';
import { Category } from 'components/CalculatorForm/tabs/CalculatorFinalTab';
import { ReactComponent as Xmark } from 'assets/icons/close.svg';
import CircleButton from 'components/common/CircleButton';
import Button from 'components/common/Button';

const columnHelper = createColumnHelper<Category>();

interface IProjectPlanTables {
  projectPlan: any;
  formValuesRef: any;
  onRecalculateProject: any;
  categories: Category[];
}

const ProjectPlanTables: FC<IProjectPlanTables> = ({
  projectPlan,
  categories,
  formValuesRef,
  onRecalculateProject,
}) => {
  const [isSubmitting, setSubmitting] = useState<boolean>();
  const { t } = useTranslation();
  const [sorting, setSorting] = React.useState<SortingState>([{ id: 'co2Savings', desc: true }]);
  const [pieChartCategories, setPieChartActiveCategories] = useState<Category[]>(
    categories.sort((a, b) => b.co2Savings - a.co2Savings),
  );

  const toggleCategory = useCallback(
    (categoryName: string) => {
      const category = pieChartCategories.find(({ name }) => name === categoryName);
      let newCategories: Category[] = [];
      if (category) {
        newCategories = pieChartCategories.filter(({ name }) => name !== categoryName);
      } else {
        const newCategory = categories.find(({ name }) => name === categoryName);
        newCategories = [newCategory, ...pieChartCategories];
      }
      setPieChartActiveCategories(newCategories);
    },
    [categories, pieChartCategories],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: () => '',
        cell: (info) => {
          const categoryName = info.getValue();
          const show = pieChartCategories.find(({ name }) => name === categoryName);
          return (
            <CircleButton onClick={() => toggleCategory(info.getValue())} variant="outline">
              {show ? <Xmark /> : null}
            </CircleButton>
          );
        },
      }),
      columnHelper.accessor('label', {
        header: () => t('plan.tableOverview.headers.category'),
        cell: (info) => {
          const isFirstRow = info.row.index === 0;
          const label = info.getValue();
          return isFirstRow ? <b>{label}</b> : label;
        },
      }),
      columnHelper.accessor('price', {
        id: 'price',
        header: () => t('plan.tableOverview.headers.price'),
        cell: (info) => {
          const isFirstRow = info.row.index === 0;
          const label = formatCurrency(info.getValue());
          return isFirstRow ? <b>{label}</b> : label;
        },
      }),
      columnHelper.accessor('savingEnergyCosts', {
        header: () => t('plan.tableOverview.headers.savingEnergyCosts'),
        cell: (info) => {
          const v = info.getValue();
          if (!v) {
            if (info.row.original.name === 'battery_storage') {
              return t('plan.tableOverview.batteryStorage.noSavingEnergyCosts');
            } else {
              return t('plan.tableOverview.noReduction');
            }
          }

          const isFirstRow = info.row.index === 0;
          const label = `${formatCurrency(v)} ${t('plan.tableOverview.perYear')}`;
          return isFirstRow ? <b>{label}</b> : label;
        },
      }),
      columnHelper.accessor('co2Savings', {
        header: () => t('plan.tableOverview.headers.co2Savings'),
        cell: (info) => {
          const v = info.getValue();
          if (!v) {
            if (info.row.original.name === 'battery_storage') {
              return t('plan.tableOverview.batteryStorage.noCO2Savings');
            } else {
              return t('plan.tableOverview.noReduction');
            }
          }

          const isFirstRow = info.row.index === 0;
          const label = `${formatValue(v)} kg ${t('plan.tableOverview.perYear')}`;
          return isFirstRow ? <b>{label}</b> : label;
        },
      }),
    ],
    [pieChartCategories, t, toggleCategory],
  );

  const table = useReactTable({
    data: categories,
    columns,
    state: { sorting },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
  });

  const onRecalculateClick = useCallback(async () => {
    setSubmitting(true);
    const { features } = formValuesRef.current;
    const newFeatures = Object.keys(features).reduce((acc, key) => {
      const category = pieChartCategories.find(({ name }) => name === key);
      return { ...acc, [key]: !!category };
    }, {});
    formValuesRef.current.features = newFeatures;
    await onRecalculateProject({ values: formValuesRef.current, isEditMode: true, currentStep: null });
    setSubmitting(false);
  }, [formValuesRef, onRecalculateProject, pieChartCategories]);

  return (
    <>
      <Row className="mb-4">
        <Col xs={12}>
          <Header className={s.totalCosts}>
            <h2>
              {t('plan.totalCosts')}
              <span className="float-right">{formatCurrency(projectPlan.price)}</span>
            </h2>
          </Header>
          <div style={{ overflowX: 'scroll' }}>
            <table className="table">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th key={header.id} colSpan={header.colSpan} style={{ cursor: 'pointer' }}>
                        {header.isPlaceholder ? null : (
                          <div
                            {...{
                              onClick: header.column.getToggleSortingHandler(),
                              style: { display: 'flex', alignItems: 'center' },
                            }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: <DropUpArrow />,
                              desc: <DropUpArrow style={{ transform: 'rotate(180deg)' }} />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
      {pieChartCategories.length !== categories.length && pieChartCategories.length ? (
        <div className="text-right">
          <Button loading={isSubmitting} disabled={isSubmitting} onClick={onRecalculateClick}>
            {t('plan.recalculateProjectBtn')}
          </Button>
        </div>
      ) : null}
      {pieChartCategories.length >= 1 ? <OverviewPieCharts {...{ categories: pieChartCategories }} /> : null}
    </>
  );
};

export default ProjectPlanTables;
