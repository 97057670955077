import { useParams } from 'react-router-dom';
import React from 'react';
import Loading from 'components/common/Loading';
import CalculatorResultOverview from 'components/CalculatorResultOverview/CalculatorResultOverview';
import useGetCO2Calculations from 'hooks/calculator/useCO2Calculations';
import Alert from 'components/common/Alert';
import { formatGraphqlErrors } from 'utils/helpers';

const CalculatorThirdTab = ({ nextStep, previousStep, currentStep }) => {
  const { projectId } = useParams();
  const { co2Calculations, loading, error } = useGetCO2Calculations(projectId);

  if (loading) return <Loading size={50} />;

  if (error) {
    return <Alert color="danger">{formatGraphqlErrors(error).message}</Alert>;
  }

  return <CalculatorResultOverview {...{ previousStep, nextStep, co2Calculations, currentStep }} />;
};

export default CalculatorThirdTab;
