import { Header, SectionWrapper } from 'components/common/heap';
import React, { useCallback } from 'react';
import { Row, Col } from 'react-grid-system';
import EnergyAndCO2Balance from './CalculationTables/EnergyAndCO2BalanceTable';
import AllCalculations from './CalculationTables/AllCalculations';
import EnergyClassesAndIndicatorsSection from './EnergyClassesAndIndicators';
import EnergyReductionAndGhgEquivalents from './EnergyReductionAndGhgEquivalents';
import { useTranslation } from 'react-i18next';
import ElectricityResult from './CalculationTables/ElectricityResult';
import useUpdateCO2CalculatedValues from 'hooks/calculator/useUpdateCO2CalculatedValues';
import { CO2_CALCULATIONS } from 'hooks/calculator/useCO2Calculations';
import { useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { useApolloClient } from '@apollo/client';
import { ReactComponent as ArrowBack } from 'assets/icons/angle-back.svg';
import { ReactComponent as ArrowForward } from 'assets/icons/angle-forward.svg';
import Button from 'components/common/Button';
import s from './CalculatorResultOverview.module.scss';

const CalculatorResultOverview = ({ co2Calculations, nextStep, previousStep }) => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const client = useApolloClient();
  const { custom_energy_requirements, co2_emissions_before } = co2Calculations;

  const updateCalculatedValue = useUpdateCO2CalculatedValues();

  const onUpdateCalculatedValue = useCallback(
    async ({ callback, fieldName, fieldValue }) => {
      const data = client.readQuery({ query: CO2_CALCULATIONS, variables: { projectId } });
      const newData = cloneDeep(data);
      newData.co2Calculations.co2_emissions_before.result[fieldName].user_input = fieldValue;
      client.writeQuery({
        query: CO2_CALCULATIONS,
        variables: { projectId },
        data: { ...newData },
      });
      callback();
      await updateCalculatedValue(projectId, { [fieldName]: fieldValue });
    },
    [client, projectId, updateCalculatedValue],
  );

  return (
    <Row>
      <Col md={12}>
        <Header>
          <h3>{t('co2CalculatorResult.houseEnergyBalance.header')}</h3>
        </Header>

        <EnergyClassesAndIndicatorsSection {...{ t, co2Calculations, nextStep }} />

        <Header>
          <h3>{t('co2CalculatorResult.potentialEnergyBalance.title')}</h3>
          <small className="muted">{t('co2CalculatorResult.potentialEnergyBalance.description')}</small>
        </Header>

        <EnergyReductionAndGhgEquivalents {...{ co2Calculations }} />

        <SectionWrapper>
          <EnergyAndCO2Balance {...{ calculations: co2Calculations.co2_emissions_before.result }} />
        </SectionWrapper>
        {custom_energy_requirements.electricity_used.user_value &&
        co2_emissions_before.result.total_energy_balance_per_year.user_input ? (
          <SectionWrapper>
            <ElectricityResult {...{ co2Calculations, onSaveButtonClick: onUpdateCalculatedValue }} />
          </SectionWrapper>
        ) : null}

        {!co2_emissions_before.result.total_energy_balance_per_year.user_input ? (
          <AllCalculations {...{ co2Calculations, onUpdateCalculatedValue }} />
        ) : null}

        <section className="mt-4 mb-8">
          <h2 className="mt-0">{t('co2CalculatorResult.potentialEnergyBalance.redevelopmentHouseLabel')}</h2>
          <div className={s.buttonsWrapper}>
            <Button onClick={previousStep} startIcon={<ArrowBack />} color="outline">
              {t('button.back')}
            </Button>
            <Button onClick={nextStep} endIcon={<ArrowForward />}>
              {t('co2CalculatorResult.improveHouseBtnLabel')}
            </Button>
          </div>
        </section>
      </Col>
    </Row>
  );
};

export default CalculatorResultOverview;
