import Checkbox from 'components/inputs/Checkbox';
import { FastField, Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback } from 'react';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';
import Button from 'components/common/Button';
import useRequestProjectPDF from 'hooks/projectPlan/useRequestProjectPDF';
import TextInput from 'components/inputs/TextInput';
import config from 'config/config';
import { toast } from 'react-toastify';

interface IRequestPdfForm {
  projectId: string;
  hideModal: () => void;
}

const validationSchema = yup.object().shape({
  email: yup.string().email('errors.invalidEmail').required('errors.fillField'),
  firstName: yup.string().required('errors.fillField'),
  lastName: yup.string().required('errors.fillField'),
  privacyPolicyConsent: yup.bool().oneOf([true], 'errors.privacyPolicyRequired'),
  contactAgreement: yup.bool(),
});

interface IFormValues {
  email: string;
  firstName: string;
  lastName: string;
  privacyPolicyConsent: boolean;
  contactAgreement: boolean;
}

const initialValues: IFormValues = {
  email: '',
  firstName: '',
  lastName: '',
  privacyPolicyConsent: false,
  contactAgreement: false,
};

const RequestPdfForm: FC<IRequestPdfForm> = ({ projectId, hideModal }) => {
  const { t } = useTranslation();
  const requestPDF = useRequestProjectPDF(hideModal);

  const onSubmit = useCallback(
    async (
      { email, firstName, lastName, contactAgreement }: IFormValues,
      { setSubmitting }: FormikHelpers<IFormValues>,
    ) => {
      await requestPDF({ input: { email, firstName, lastName, contactAgreement }, projectId });
      setSubmitting(false);
      toast.success(t('plan.successMessage'));
    },
    [requestPDF, projectId, t],
  );

  return (
    <Formik {...{ initialValues, validationSchema, onSubmit }}>
      {({ isSubmitting }) => (
        <Form>
          <FastField name="firstName" component={TextInput} label={t('user.firstName')} />
          <FastField name="lastName" component={TextInput} label={t('user.lastName')} />
          <FastField name="email" component={TextInput} type="email" label={t('auth.email')} />
          <FastField
            name="contactAgreement"
            component={Checkbox}
            skipScrollingOnChange
            label={t('auth.contactAgreement')}
          />
          <FastField
            name="privacyPolicyConsent"
            component={Checkbox}
            skipScrollingOnChange
            label={
              <div>
                <Trans
                  i18nKey="auth.privacyPolicyLabel"
                  components={{
                    privacyPolicyUrl: <a href={config.privacyPolicyUrl} rel="noopener noreferrer" target="_blank" />,
                  }}
                />
              </div>
            }
          />

          <div className="text-center">
            <Button type="submit" disabled={isSubmitting} loading={isSubmitting}>
              {t('plan.pdfCTASection.requestPdfBtn')}
              <Download className="ml-1" />
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RequestPdfForm;
