import React from 'react';
import { useNavigate } from 'react-router-dom';
import routePaths from 'router/routePaths';
import { ReactComponent as ArrowForward } from 'assets/icons/angle-forward.svg';
import PageTitle from 'components/common/PageTitle';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'components/common/Button';
import { ReactComponent as RenovationHouseImg } from 'assets/images/renovation-house.svg';
import s from './CreateProject.module.scss';
import { FastField, Form, Formik } from 'formik';
import Checkbox from 'components/inputs/Checkbox';
import * as yup from 'yup';
import useCreateProject from 'hooks/project/useCreateProject';

const validationSchema = yup.object().shape({
  termsOfUseConsent: yup.bool().oneOf([true], 'errors.termsOfUseRequired'),
});

const CreateProject = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const initialValues = { termsOfUse: false };
  const onSubmit = useCreateProject();

  return (
    <div className={s.container}>
      <div className={s.inner}>
        <PageTitle className="text-center">{t('pageTitles.createProject')}</PageTitle>
        <RenovationHouseImg />
        <Formik {...{ initialValues, onSubmit, validationSchema }}>
          {({ isSubmitting }) => (
            <Form className={s.form}>
              <div className={s.termsOfUseConsent}>
                <FastField
                  name="termsOfUseConsent"
                  component={Checkbox}
                  skipScrollingOnChange
                  label={
                    <Trans
                      i18nKey="auth.termsOfUseLabel"
                      components={{
                        termsOfUseUrl: (
                          <div
                            onClick={() => navigate(routePaths.termsOfUse)}
                            style={{ textDecoration: 'underline', color: '#0086cd' }}
                          >
                            {t('auth.termsOfUseLinkText')}
                          </div>
                        ),
                      }}
                    />
                  }
                />
              </div>
              <div className={s.btnWrapper}>
                <Button disabled={isSubmitting} loading={isSubmitting} type="submit" endIcon={<ArrowForward />}>
                  {t('button.start')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateProject;
