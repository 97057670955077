import { Category } from 'components/CalculatorForm/tabs/CalculatorFinalTab';
import { clamp } from 'lodash';
import React, { useMemo } from 'react';
import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatValue } from 'utils/helpers';
import PlanningCategoryPieChart, { COLORS } from './PieChart';

interface IOverviewPieCharts {
  categories: Category[];
}

const OverviewPieCharts: FC<IOverviewPieCharts> = ({ categories }) => {
  const { t } = useTranslation();
  const costs = useMemo(
    () =>
      categories
        .map(({ label, price }, idx) => ({
          name: `${label} ${formatCurrency(price)}`,
          value: price,
          formatter: formatCurrency,
          fill: COLORS[idx],
        }))
        .sort((a, b) => b.value - a.value),
    [categories],
  );
  const co2Savings = useMemo(
    () =>
      categories
        .filter(({ co2Savings }) => !!co2Savings)
        .map(({ label, co2Savings }, idx) => ({
          name: `${label} ${formatValue(co2Savings)} kg/a`,
          value: co2Savings,
          formatter: (v: number) => `${formatValue(v)} kg`,
          fill: COLORS[idx],
        }))
        .sort((a, b) => b.value - a.value),
    [categories],
  );
  const savingEnergyCosts = useMemo(
    () =>
      categories
        .filter(({ savingEnergyCosts }) => !!savingEnergyCosts)
        .map(({ label, savingEnergyCosts }, idx) => ({
          name: `${label} ${formatCurrency(savingEnergyCosts)}`,
          value: savingEnergyCosts,
          formatter: formatCurrency,
          fill: COLORS[idx],
        }))
        .sort((a, b) => b.value - a.value),
    [categories],
  );

  const costsLabel = useMemo(() => {
    const sum = costs.reduce((acc, { value }) => acc + value, 0);
    return `${t('plan.chartLabel.totalCosts')}: ${formatCurrency(sum)}`;
  }, [costs, t]);

  const co2SavingsLabel = useMemo(() => {
    const sum = co2Savings.reduce((acc, { value }) => acc + value, 0);
    return `${t('plan.chartLabel.co2Savings')}: ${formatValue(sum)} kg/a`;
  }, [co2Savings, t]);

  const energyCostsLabel = useMemo(() => {
    const sum = savingEnergyCosts.reduce((acc, { value }) => acc + value, 0);
    return `${t('plan.chartLabel.costSavings')}: ${formatCurrency(sum)}`;
  }, [savingEnergyCosts, t]);

  const chartHeight = useMemo(() => clamp(90 * co2Savings.length, 440, 530), [co2Savings.length]);

  return (
    <Row>
      <Col xs={12} lg={6} style={{ height: `${chartHeight + costs.length * 15}px` }}>
        <PlanningCategoryPieChart data={costs} label={costsLabel} />
      </Col>
      {co2Savings.length ? (
        <Col xs={12} lg={6} style={{ height: `${chartHeight + co2Savings.length * 10}px` }}>
          <PlanningCategoryPieChart data={co2Savings} label={co2SavingsLabel} />
        </Col>
      ) : null}
      {savingEnergyCosts.length ? (
        <Col xs={12} lg={6} style={{ height: `${chartHeight + savingEnergyCosts.length * 10}px` }}>
          <PlanningCategoryPieChart data={savingEnergyCosts} label={energyCostsLabel} />
        </Col>
      ) : null}
    </Row>
  );
};

export default OverviewPieCharts;
