import React, { useMemo } from 'react';
import { Col, Row } from 'react-grid-system';
import { ReactComponent as RenovationHouse } from 'assets/images/renovation-house.svg';
import { get } from 'lodash';
import EnergyGhgEquivalents from './EnergyGhgEquivalents';
import EnergyReductionItems from './EnergyReductionItems/EnergyReductionItems';
import { useTranslation } from 'react-i18next';

const EnergyReductionAndGhgEquivalents = ({ co2Calculations }) => {
  const { t } = useTranslation();

  const { result } = co2Calculations.co2_emissions_before;
  const equivalentValues = useMemo(
    () => ({
      energyGhgEquivalentPlane: get(result, 'energy_ghg_equivalent_plane', 0),
      energyGhgEquivalentCar: get(result, 'energy_ghg_equivalent_car', 0),
      energyGhgEquivalentTrees: get(result, 'energy_ghg_equivalent_trees', 0),
    }),
    [result],
  );

  return (
    <>
      <Row className="mb-4">
        <Col lg={6}>
          <EnergyReductionItems {...{ co2Calculations, t }} />
        </Col>
        <Col lg={6}>
          <RenovationHouse />
        </Col>
      </Row>
      <EnergyGhgEquivalents {...{ equivalentValues }} />
    </>
  );
};

export default EnergyReductionAndGhgEquivalents;
