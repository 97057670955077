import { useParams } from 'react-router-dom';
import React, { useMemo } from 'react';
import Loading from 'components/common/Loading';
import { Col, Row } from 'react-grid-system';
import PageTitle from 'components/common/PageTitle';
import { useTranslation } from 'react-i18next';
import WarningMessage from 'components/common/WarningMessage/WarningMessage';
import Button from 'components/common/Button';
import CO2StatusProjectPlanSection from 'components/CalculatorResultOverview/CO2StatusProjectPlanSection';
import useProjectOverview from 'hooks/projectPlan/useProjectOverview';
import ProjectPlanTables from 'components/ProjectOverview/ProjectPlanTables';
import { ReactComponent as ArrowBack } from 'assets/icons/angle-back.svg';
import { get, isNil, round } from 'lodash';
import s from './CalculatorFinalTab.module.scss';
import Modal from 'components/common/Modal';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import { ReactComponent as ShoppingCart } from 'assets/icons/shopping-cart.svg';
import { useModal } from 'components/common/Modal/Modal';
import RequestPdfForm from './RequestPdfForm';
import config from 'config/config';
import { formatGraphqlErrors } from 'utils/helpers';
import GoHomeAlert from 'components/ProjectOverview/GoHomeAlert';
import BeforeAndAfterOverview from './BeforeAndAfterTable';

type InitialCategory = {
  label: string;
  name: string;
  user_price: number | null;
  calculated_price: number;
  category: string;
  costs: string;
  savingEnergyCosts: number;
  co2Savings: number;
};

export type Category = {
  label: string;
  name: string;
  price: number;
  savingEnergyCosts: number;
  co2Savings: number;
  toggleCategory: (s: string) => void;
};

const category2CO2SavingsField = [
  { renovation: 'insulation_facade', categories: ['facade_insulation'] },
  { renovation: 'new_windows', categories: ['new_windows'] },
  { renovation: 'insulation_basement_ceiling', categories: ['insulation_basement_ceiling'] },
  { renovation: 'heating_system', categories: ['heating_system'] },
  { renovation: 'solar_power_system', categories: ['solar_power_system'] },
  { renovation: 'controlled_living_space_ventilation', categories: ['domestic_ventilation'] },
  {
    renovation: 'insulation_top_ceiling',
    categories: ['insulation_top_ceiling', 'roof'],
  },
];

interface ICTASectionProps {
  projectId: string;
}

const PdfCTASection: FC<ICTASectionProps> = ({ projectId }) => {
  const { t } = useTranslation();
  const { showModal, isVisible, hideModal } = useModal();

  return (
    <>
      <div className={s.ctaSection}>
        <h2 className="text-primary">{t('plan.pdfCTASection.description')}</h2>
        <Button onClick={showModal} className={s.ctaBtn}>
          {t('plan.pdfCTASection.requestPdfBtn')}
          <Download className="ml-1" />
        </Button>
      </div>
      <Modal {...{ isVisible, close: hideModal, headerText: t('plan.modalHeader') }}>
        <RequestPdfForm {...{ projectId, hideModal }} />
      </Modal>
    </>
  );
};

const OnlineShopCTASection: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={s.ctaSection}>
        <h2 className="text-primary">{t('plan.onlineShoppingSection.description')}</h2>
        <a href={config.swtShopUrl} rel="noopener noreferrer" target="_blank">
          <Button className={s.ctaBtn}>
            {t('plan.onlineShoppingSection.toShopBtn')}
            <ShoppingCart className="ml-1" />
          </Button>
        </a>
      </div>
    </>
  );
};

const CalculatorFinalTab = ({ goToStep, formValuesRef, onRecalculateProject }) => {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const { projectPlan, project, energyRenovations, loading, error } = useProjectOverview(projectId);

  const categories: Category[] = useMemo(() => {
    const planningCategories = get(projectPlan, 'planningCategories', []).map((category: InitialCategory) => {
      const { label, user_price, calculated_price, name } = category;
      const saving = category2CO2SavingsField.find(({ categories }) => categories.includes(category.name));
      const co2Value = get(energyRenovations, `${saving?.renovation}.co2_saving`, 0);
      const co2Savings = round(co2Value, 2);

      const energyCosts = get(energyRenovations, `${saving?.renovation}.saving_energy_costs`, 0);
      const savingEnergyCosts = round(energyCosts, 2);

      const price = !isNil(user_price) ? user_price : calculated_price;
      return { label: t(label), price, co2Savings, savingEnergyCosts, name, t: () => undefined };
    });

    const roofCategoryIdx = planningCategories.findIndex(({ name }) => name === 'roof');
    const topCeilingCategoryIdx = planningCategories.findIndex(({ name }) => name === 'insulation_top_ceiling');
    if (roofCategoryIdx !== -1 && topCeilingCategoryIdx !== -1) {
      const el = planningCategories[roofCategoryIdx];
      planningCategories[roofCategoryIdx] = { ...el, savingEnergyCosts: 0, co2Savings: 0 };
    }
    return planningCategories;
  }, [energyRenovations, projectPlan, t]);

  if (loading) return <Loading size={50} />;
  if (error) return <GoHomeAlert message={formatGraphqlErrors(error).message} />;
  if (!project) return <GoHomeAlert message={t('errors.projectNotFound')} />;

  if (projectPlan?.price === 0) {
    return (
      <Row justify="center">
        <Col md={8} lg={6}>
          <WarningMessage
            title={t('pageTitles.plan')}
            message={t('plan.completePreviousStep')}
            btnLabel={t('plan.completePreviousStepBtn')}
            onBtnClick={() => goToStep(4)}
          />
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row>
        <Col>
          <PdfCTASection {...{ projectId }} />
        </Col>
      </Row>
      <Row>
        <Col
          xs={12}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: '8px',
          }}
        >
          <PageTitle className="text-left">{t('pageTitles.plan')}</PageTitle>
          <Button onClick={() => goToStep(1)} startIcon={<ArrowBack />} color="outline">
            {t('plan.editValuesBtn')}
          </Button>
        </Col>
      </Row>
      <CO2StatusProjectPlanSection projectId={project._id} />
      <ProjectPlanTables {...{ projectPlan, categories, formValuesRef, onRecalculateProject }} />
      <BeforeAndAfterOverview {...{ project }} />
      <Row>
        <Col className="mt-4">
          <PdfCTASection {...{ projectId }} />
        </Col>
      </Row>

      <Row>
        <Col className="mt-4">
          <OnlineShopCTASection />
        </Col>
      </Row>
    </>
  );
};

export default CalculatorFinalTab;
