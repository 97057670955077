/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useEffect, useCallback } from 'react';
import Switch from 'react-switch';
import cn from 'classnames';
import theme from 'config/theme';
import { areEqualFields } from 'utils/form';
import isNil from 'lodash/isNil';
import s from './Switch.module.scss';
import { useTranslation } from 'react-i18next';

const SwitchInput = ({ field, form, sideEffectOnChange, onChange, ...props }) => {
  const { label, style } = props;
  const { touched, errors, setFieldValue } = form;
  const { t } = useTranslation();

  const handleChange = useCallback(
    (value) => {
      setFieldValue(field.name, value);
      if (typeof sideEffectOnChange === 'function') sideEffectOnChange({ value, form });
    },
    [field.name, form, setFieldValue, sideEffectOnChange],
  );

  useEffect(() => {
    if (isNil(field.value)) setFieldValue(field.name, false);
  }, [field.name, field.value, setFieldValue]);

  const hasError = touched[field.name] && errors[field.name];

  return (
    <div className={cn('field-wrapper', s.switchWrapper)} style={style}>
      <label className={s.switchLabel} htmlFor={field.name}>
        {t(label)}
      </label>
      <Switch
        {...field}
        onChange={onChange || handleChange}
        checked={field.value || false}
        handleDiameter={15}
        offColor="#fff"
        onHandleColor={theme.primaryColor}
        offHandleColor={theme.grayColor}
        onColor="#fff"
        height={20}
        width={40}
        uncheckedIcon={false}
        checkedIcon={false}
        className={cn(s.switch, { 'error-input': hasError }, field.value ? s.switchOn : null)}
      />
      {hasError && <div className="text-danger">{t(errors[field.name])}</div>}
    </div>
  );
};

export default memo(SwitchInput, areEqualFields);
