import i18n from 'i18n';
import * as yup from 'yup';

const validZipCodes = /^[0-9]{5}$/;

const validationSchema = yup.object().shape({
  name: yup.string().required('errors.fillField'),
  zip: yup.string().nullable().matches(validZipCodes, { message: 'errors.wrongZipCode' }).required('errors.fillField'),
  house_area: yup
    .number()
    .required('errors.fillField')
    .min(25, i18n.t('errors.values_within_range', { min: 25, max: 300 }))
    .max(300, i18n.t('errors.values_within_range', { min: 25, max: 300 })),
  floors_number: yup.number().nullable().required('errors.chooseOne'),
});

export default validationSchema;
