import React, { useMemo } from 'react';
import { formatCurrency, formatValue } from 'utils/helpers';
import cn from 'classnames';
import pick from 'lodash/pick';
import { Small } from 'components/common/heap';
import s from '../CalculatorResultOverview.module.scss';
import { useTranslation } from 'react-i18next';

const whiteListValues = [
  'total_energy_balance_per_year',
  'energy_ghg_balance_per_year',
  'energy_ghg_equivalent_car',
  'energy_ghg_equivalent_plane',
  'energy_ghg_equivalent_trees',
  'total_energy_costs',
];

const EnergyAndCO2Balance = ({
  calculations,
  tableHeader = 'co2CalculatorResult.energyAndCo2BalanceResult.tableTitle',
}) => {
  const { t } = useTranslation();

  const energyResultValues: any = useMemo(() => {
    return Object.entries(pick(calculations, whiteListValues)).reduce(
      // (acc, [key, value]) => ({ ...acc, [key]: parseFloat(value).toFixed(3) * 1 }), // TODO: fix
      (acc, [key, value]) => ({
        ...acc,
        [key]: Number.isFinite(value.user_input)
          ? parseFloat(value.user_input)
          : parseFloat(value.calculated_value || value),
      }),
      {},
    );
  }, [calculations]);

  return (
    <div style={{ overflowX: 'scroll' }}>
      <table className={cn('table last-col-text-right', s.resultTable)}>
        <thead>
          <tr>
            <th colSpan={2}>
              <h4>{t(tableHeader)}</h4>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {t('co2CalculatorResult.energyAndCo2BalanceResult.totalEnergyBalancePerYear')}
              <Small>{t('co2CalculatorResult.energyAndCo2BalanceResult.totalEnergyBalancePerYearDescription')}</Small>
            </td>
            <td>{formatValue(energyResultValues.total_energy_balance_per_year)} kWh/a</td>
          </tr>
          <tr>
            <td>
              {t('co2CalculatorResult.energyAndCo2BalanceResult.energyGhgBalancePerYear')}
              <Small>{t('co2CalculatorResult.energyAndCo2BalanceResult.energyGhgBalancePerYearDescription')}</Small>
            </td>
            <td>{formatValue(energyResultValues.energy_ghg_balance_per_year)} kg/a</td>
          </tr>
          {/* <tr>
            <td>
              {t('co2CalculatorResult.energyAndCo2BalanceResult.energyGhgEquivalentCar')}
              <Small>{t('co2CalculatorResult.energyAndCo2BalanceResult.energyGhgEquivalentCarDescription')}</Small>
            </td>
            <td>{formatValue(energyResultValues.energy_ghg_equivalent_car)} km</td>
          </tr>
          <tr>
            <td>
              {t('co2CalculatorResult.energyAndCo2BalanceResult.energyGhgEquivalentPlane')}
              <Small>{t('co2CalculatorResult.energyAndCo2BalanceResult.energyGhgEquivalentPlaneDescription')}</Small>
            </td>
            <td>
              {formatValue(energyResultValues.energy_ghg_equivalent_plane)}
              <Small>{t('co2CalculatorResult.numberOfEconomyClassFlights')}</Small>
            </td>
          </tr>
          <tr>
            <td>
              {t('co2CalculatorResult.energyAndCo2BalanceResult.energyGhgEquivalentTrees')}
              <Small>{t('co2CalculatorResult.energyAndCo2BalanceResult.energyGhgEquivalentTreesDescription')}</Small>
            </td>
            <td>
              {formatValue(energyResultValues.energy_ghg_equivalent_trees)}
              <Small>{t('co2CalculatorResult.numberStoredTrees')}</Small>
            </td>
          </tr> */}
          <tr>
            <td>
              {t('co2CalculatorResult.energyAndCo2BalanceResult.totalEnergyCosts')}
              <Small>{t('co2CalculatorResult.energyAndCo2BalanceResult.totalEnergyCostsDescription')}</Small>
            </td>
            <td>{formatCurrency(energyResultValues.total_energy_costs)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default EnergyAndCO2Balance;
