import React, { useMemo } from 'react';
import { Col, Hidden, Row, Visible } from 'react-grid-system';
import { ReactComponent as CarIcon } from 'assets/icons/calculator/car.svg';
import { ReactComponent as PlaneIcon } from 'assets/icons/calculator/plane.svg';
import { ReactComponent as TreeIcon } from 'assets/icons/calculator/tree.svg';
import { formatValue } from 'utils/helpers';
import s from './EnergyGhgEquivalents.module.scss';
import { useTranslation } from 'react-i18next';

function EnergyGhgEquivalent({ icon: Icon, label, t, value }) {
  return (
    <div className={s.equivalent}>
      <div className={s.circle}>
        <Icon />
      </div>
      <p className={s.text}>{t(label, { value: formatValue(value) })}</p>
    </div>
  );
}

function getEnergyEquivalents(values) {
  return [
    {
      key: 'energyGhgEquivalentPlane',
      value: values.energyGhgEquivalentPlane,
      label: 'co2CalculatorResult.energyGhgEquivalents.equivalentPlane',
      icon: PlaneIcon,
    },
    {
      key: 'energyGhgEquivalentCar',
      value: values.energyGhgEquivalentCar,
      label: 'co2CalculatorResult.energyGhgEquivalents.equivalentCar',
      icon: CarIcon,
    },
    {
      key: 'energyGhgEquivalentTrees',
      value: values.energyGhgEquivalentTrees,
      label: 'co2CalculatorResult.energyGhgEquivalents.equivalentTrees',
      icon: TreeIcon,
    },
  ];
}

const EnergyGhgEquivalents = ({ equivalentValues, costOverviewTab = false }) => {
  const { t } = useTranslation();
  const energyEquivalents = useMemo(() => getEnergyEquivalents(equivalentValues), [equivalentValues]);

  return (
    <Row justify="between">
      <Visible md>
        <Col md={12}>
          <Row>
            <Col md={8}>
              <div className={s.almostRectangle}>
                {t(`co2CalculatorResult.energyGhgEquivalents.${costOverviewTab ? 'reduction' : 'label'}`)}
              </div>
            </Col>
          </Row>
        </Col>
      </Visible>

      <Hidden xs sm md>
        <Col lg={5}>
          <div className={s.almostRectangle}>
            {t(`co2CalculatorResult.energyGhgEquivalents.${costOverviewTab ? 'reduction' : 'label'}`)}
          </div>
        </Col>
      </Hidden>

      {energyEquivalents.map((v) => (
        <Col key={v.key} md={4} lg={2}>
          <EnergyGhgEquivalent {...v} t={t} />
        </Col>
      ))}
    </Row>
  );
};

export default EnergyGhgEquivalents;
