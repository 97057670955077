import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { calculateTotal } from 'components/CalculatorForm/tabs/CalculatorSecondTab';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency, formatValue } from 'utils/helpers';

interface IFuelMatrixItem {
  fuelType: string;
  userInput: number;
  energy: number;
  costs: number;
  ghg: number;
}

interface ICustomEnergyRequirementsTable {
  fuelMatrix: IFuelMatrixItem[];
}

const columnHelper = createColumnHelper<IFuelMatrixItem>();

const CustomEnergyRequirementsTable: FC<ICustomEnergyRequirementsTable> = ({ fuelMatrix }) => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      columnHelper.accessor('fuelType', {
        header: () => t('co2CalculatorResult.customEnergyRequirementsTable.headers.fuelType'),
        cell: (info) => (
          <div className="text-left">
            {t(`co2CalculatorResult.customEnergyRequirementsTable.fuelType.${info.getValue()}`)}
          </div>
        ),
      }),
      columnHelper.accessor('userInput', {
        header: () => t('co2CalculatorResult.customEnergyRequirementsTable.headers.userInput'),
        cell: (info) => formatValue(info.getValue()),
      }),
      columnHelper.accessor('energy', {
        header: () => t('co2CalculatorResult.customEnergyRequirementsTable.headers.energy'),
        cell: (info) => `${formatValue(info.getValue())} kWh`,
        footer: () => `${formatValue(calculateTotal(fuelMatrix, 'energy'))} kWh`,
      }),
      columnHelper.accessor('costs', {
        header: () => t('co2CalculatorResult.customEnergyRequirementsTable.headers.costs'),
        cell: (info) => formatCurrency(info.getValue()),
        footer: () => formatCurrency(calculateTotal(fuelMatrix, 'costs')),
      }),
      columnHelper.accessor('ghg', {
        header: () => t('co2CalculatorResult.customEnergyRequirementsTable.headers.ghg'),
        cell: (info) => `${formatValue(info.getValue())} kg/a`,
        footer: () => `${formatValue(calculateTotal(fuelMatrix, 'ghg'))} kg/a`,
      }),
    ],
    [fuelMatrix, t],
  );

  const table = useReactTable({
    data: fuelMatrix,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div style={{ overflowX: 'scroll' }} className="mb-4">
      <table className="table">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="text-right">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  <div className="text-right">
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  );
};

export default CustomEnergyRequirementsTable;
