import { Locales } from 'i18n';
import { cloneDeep } from 'lodash';
import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { toast } from 'react-toastify';
import config from 'config/config';

export const formatValue = (value) => {
  const locale = Locales.de;
  return Intl.NumberFormat(locale).format(value);
};

export const formatCurrency = (() => {
  // const locale = localStorage?.getItem('language') || 'de';
  const formatter = new Intl.NumberFormat(config.defaultLocale, { style: 'currency', currency: 'EUR' });
  return (number) => {
    const parts = formatter.formatToParts(number);
    const strArray = [];
    // filter out the decimal part
    parts.forEach((part) => {
      if (['decimal', 'fraction'].includes(part.type)) return;
      strArray.push(part.value);
    });
    return strArray.join('');
  };
})();

export const localizeWizard = (object, options) => {
  const {
    formatter,
    keys = [
      'units',
      'title',
      'label',
      'subtitle',
      'description',
      'fieldLabel',
      'summaryTitle',
      'submitButtonLabel',
      'text',
      'note',
    ],
  } = options;
  const result = cloneDeep(object);
  function traverse(subtree) {
    if (!subtree) return;
    Object.entries(subtree).forEach(([key, value]) => {
      if (key === 'icon') return;
      if (keys.includes(key)) {
        if (subtree.skipI18n) return;
        // eslint-disable-next-line no-param-reassign
        subtree[key] = formatter(value);
      } else if (typeof value === 'object') {
        traverse(value);
      }
    });
  }
  traverse(result);
  return result;
};

export type ErrorResponse = Error | ApolloError;

// Type must be augmented because of https://github.com/apollographql/apollo-link/issues/536
type NetworkError = ApolloError & { result: { errors: Error[] } };

export const formatGraphqlErrors = (error: ApolloError) => {
  const graphqlErrorMsg = Array.isArray(error.graphQLErrors)
    ? error.graphQLErrors.map((err: GraphQLError) => err.message).join('\n')
    : null;

  const networkError = error.networkError as NetworkError;
  const networkErrorResultMsg = Array.isArray(networkError?.result?.errors)
    ? networkError?.result?.errors.map((err) => err.message).join('\n')
    : null;
  // const networkErrorMsg = networkError ? i18n.t('errors.serverConnectionError') : null;
  const networkErrorMsg = networkError
    ? 'Verbindung zum Server derzeit nicht möglich, bitte Internetverbindung prüfen!'
    : null;

  const msg = graphqlErrorMsg || networkErrorResultMsg || networkErrorMsg;
  const e: ErrorResponse = msg ? new Error(msg) : error;

  return e;
};

export const toastErrorMessage = (error: ApolloError) => toast.error(formatGraphqlErrors(error).message);
