import React from 'react';
import theme from 'config/theme';
import { Label, Text, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

interface IPieChart {
  data: { value: any; name: string; formatter: (v: number) => string }[];
  label?: string;
}

export const COLORS = [
  theme.primaryColor,
  theme.darkGreenColor,
  theme.yellowColor,
  theme.mediumGrayColor,
  theme.dangerColor,
  theme.blueColor,
  'rgb(136,204,238)',
  'rgb(74,170,153)',
  'rgb(102,153,204)',
  'rgb(153,153,51)',
  'rgb(221,203,119)',
  'rgb(204,102,119)',
  'rgb(54,120, 51)',
  'rgb(170,68,102)',
  'rgb(64,199,137)',
  'rgb(205, 24, 24)',
  'rgb(250,105,55)',
];

const CustomTooltip = ({ payload, formatter }: any) => {
  const { name, value, payload: p } = (payload && payload[0]) || {};
  return (
    <div className="bg-white p-2" style={{ maxWidth: '300px', color: p?.fill, border: '1px solid silver' }}>
      {`${name} : ${formatter(value)}`}
    </div>
  );
};

const PlanningCategoryPieChart: FC<IPieChart> = ({ data, label }) => {
  return (
    <ResponsiveContainer height="100%" width="100%">
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius="80%"
          innerRadius="50%"
          paddingAngle={2}
          fill={theme.primaryColor}
        >
          {label ? (
            <Label
              width={110}
              content={(props: any) => {
                const { viewBox, width } = props;
                const { cx, cy } = viewBox;
                const positioningProps: any = {
                  x: cx,
                  y: cy + 5,
                  width,
                  textAnchor: 'middle',
                  verticalAnchor: 'middle',
                  textAlign: 'center',
                  fill: 'black',
                  fontSize: '16px',
                  fontWeight: 'bold',
                };
                return <Text {...positioningProps}>{label}</Text>;
              }}
            />
          ) : null}
        </Pie>
        <Tooltip content={<CustomTooltip formatter={data[0]?.formatter} />} />
        <Legend
          iconSize={20}
          iconType="circle"
          wrapperStyle={{
            fontSize: '14px',
            marginTop: '200px',
            lineHeight: '24px',
          }}
          align="left"
          verticalAlign="bottom"
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PlanningCategoryPieChart;
