import gql from 'graphql-tag';

export const CO2_CALCULATOR_RESULT = gql`
  fragment co2CalculatorResult on CO2CalculatorCalculation {
    specific_heating_demand
    heating_factor_kea
    heating_factor_ghg
    total_heating_demand
    primary_energy_for_heating {
      calculated_value
      user_input
    }
    heating_ghg_emission
    heating_ghg_equivalent_car
    heating_ghg_equivalent_plane
    heating_ghg_equivalent_trees
    total_heating_costs

    primary_energy_for_hot_water {
      calculated_value
      user_input
    }
    hot_water_ghg_emission
    hot_water_ghg_equivalent_car
    hot_water_ghg_equivalent_plane
    hot_water_ghg_equivalent_trees
    total_hot_water_costs

    total_power_consumption_per_year {
      calculated_value
      user_input
    }
    total_power_production_per_year
    own_consumption
    power_consumption_from_energy
    electrical_feed_into_grid
    ghg_savings_solar_power_system
    ghg_balance_of_power_consumption
    electricity_ghg_equivalent_car
    electricity_ghg_equivalent_plane
    electricity_ghg_equivalent_trees
    solar_power_system_helps_to_save
    total_electricity_costs

    total_energy_balance_per_year {
      user_input
      calculated_value
    }
    energy_ghg_balance_per_year {
      user_input
      calculated_value
    }
    energy_ghg_equivalent_car
    energy_ghg_equivalent_plane
    energy_ghg_equivalent_trees
    total_energy_costs {
      user_input
      calculated_value
    }
  }
`;
