/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Locales } from 'i18n';

type AppConfig = {
  defaultLocale: Locales;
  graphqlServerUrl: string;
  isDevelopment: boolean;
  isProduction: boolean;
  privacyPolicyUrl: string;
  sentryDSN: string;
  swtShopUrl: string;
};

const config: AppConfig = {
  defaultLocale: process.env.REACT_APP_DEFAULT_LOCALE ? (process.env.REACT_APP_DEFAULT_LOCALE! as Locales) : Locales.de,
  graphqlServerUrl: process.env.REACT_APP_GRAPHQL_SERVER_URL || '/graphql',
  isDevelopment: process.env.NODE_ENV !== 'production',
  isProduction: process.env.NODE_ENV === 'production',
  privacyPolicyUrl: process.env.REACT_APP_PRIVACY_POLICY_URL!,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN!,
  swtShopUrl: process.env.REACT_APP_SWT_SHOP_URL!,
};

export default config;
