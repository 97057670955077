import React, { useCallback, useMemo } from 'react';
import StepWizard from 'react-step-wizard';
import useFormValuesRef from 'hooks/useFormValuesRef';
import CalculatorFourthTab from './tabs/CalculatorFourthTab';
import CalculatorFifthTab from './tabs/CalculatorFifthTab';
import CalculatorFirstStep from './tabs/CalculatorFirstTab';
import Nav from './Nav';
import CalculatorThirdTab from './tabs/CalculatorThirdTab';
import CalculatorSecondTab from './tabs/CalculatorSecondTab';
import CalculatorFinalTab from './tabs/CalculatorFinalTab';
import { pick } from 'lodash';
import { customEnergyRequirementWhiteList } from 'pages/ProjectDetailsCalculator.data';

const formValueMock = {
  name: 'My house',
  country: 'DE',
  zip: '14141',
  heated_living_area: 80,
  kind_of_house: 'single_family_house',
  energy_standard: 'between_1960_and_1975',
  renovations: [],
  is_solar_heating_system: false,
  solar_heating_system_type: 'heating_and_hot_water',
  number_of_people: 4,
  heating_system: 'natural_gas',
  age_of_heating_system: 'older_than_20_years',
  temperature: 'less_than_21',
  hot_water_producer: 'heating_system',
  amount_of_hot_water: 'medium',
  is_devices_younger_than_10_years: true,
  power_consumers: [],
  is_solar_power_system: false,
  solar_power_system_size: null,
};

function shouldRenderSecondTab(values) {
  return Object.values(pick(values, customEnergyRequirementWhiteList)).some((n) => n > 0);
}

const CalculatorForm = ({ initialValues, onSubmit, onRecalculateProject, isEditMode, step }) => {
  const formValuesRef = useFormValuesRef({ formValueMock, initialValues });
  const [renderSecondTab, setTabRender] = React.useState<boolean>(false);

  const tabs = useMemo(
    () => [
      { component: CalculatorFirstStep, label: 'project.tabs.firstTab' },
      ...(renderSecondTab ? [{ component: CalculatorSecondTab, label: 'project.tabs.secondTab' }] : []),
      { component: CalculatorThirdTab, label: 'project.tabs.thirdTab' },
      { component: CalculatorFourthTab, label: 'project.tabs.fourthTab' },
      { component: CalculatorFifthTab, label: 'project.tabs.fifthTab' },
      { component: CalculatorFinalTab, label: 'project.tabs.finalTab' },
    ],
    [renderSecondTab],
  );

  const onSubmitStep = useCallback(
    (values) => {
      formValuesRef.current = { ...formValuesRef.current, ...values };
      const shouldRender = shouldRenderSecondTab(formValuesRef.current);
      if (shouldRender !== renderSecondTab) setTabRender(shouldRender);
    },
    [formValuesRef, renderSecondTab],
  );

  const onBack = useCallback(
    (values, callback) => {
      onSubmitStep(values);
      callback();
    },
    [onSubmitStep],
  );

  return (
    <StepWizard
      initialStep={step}
      transitions={{}}
      isLazyMount
      nav={<Nav isEditMode={isEditMode} tabs={tabs} />}
      className="pb-4"
    >
      {tabs.map(({ label, component: C }) => (
        <C
          key={label}
          {...{ onSubmitStep, onBack, formValuesRef, initialValues, onSubmit, onRecalculateProject, isEditMode }}
        />
      ))}
    </StepWizard>
  );
};

export default CalculatorForm;
