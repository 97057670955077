import { useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import { toastErrorMessage } from 'utils/helpers';
import { LAST_PROJECT_ID_QUERY } from './useLastProjectId';
import { generatePath, useNavigate } from 'react-router-dom';
import routePaths from 'router/routePaths';

const QUICK_CALCULATOR_MUTATION = gql`
  mutation quickCalculator($formData: QuickCalculatorInput!) {
    quickCalculator(formData: $formData) {
      projectId
    }
  }
`;

const initialValues = {
  name: 'Mein Wohntraum',
  type: 'renovation_house',
  country: 'DE',
};

const useCreateProject = () => {
  const client = useApolloClient();
  const navigate = useNavigate();

  const quickCalculator = useCallback(async () => {
    try {
      const res = await client.mutate({
        mutation: QUICK_CALCULATOR_MUTATION,
        variables: { formData: initialValues },
      });

      const result = res.data[Object.keys(res.data)[0]];

      client.writeQuery({
        query: LAST_PROJECT_ID_QUERY,
        data: { lastProject: { _id: result.projectId, __typename: 'Project' } },
      });

      navigate(generatePath(routePaths.project, { projectId: result.projectId }));
      return result;
    } catch (error) {
      toastErrorMessage(error);
    }
  }, [client, navigate]);
  return quickCalculator;
};

export default useCreateProject;
